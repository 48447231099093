import { useLocation } from '@reach/router'

export const useSearchParams = <Params extends { [K in keyof Params]?: string }>(): {
  [p in keyof Params]?: string
} => {
  const searchParameters = new URLSearchParams(useLocation().search)

  return Object.fromEntries(searchParameters) as {
    [p in keyof Params]?: string
  }
}
