import { Helmet } from 'react-helmet'
import imgLogo from '@/assets/images/logo.png'
import { useTranslation } from 'react-i18next'
import { useToggle } from '@/hooks/use-toggle'
import { useSearchParams } from '@/hooks/use-search-params'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { passwordReset } from '@/features/auth/service'
import { Button, Card, InputField } from '@blockchain-traceability-sl/tailwind-components'
import ReCAPTCHA from 'react-google-recaptcha'
import { useConfig } from '@/config'
import { navigate, PageProps } from 'gatsby'
import { getTranslatedPath } from '@/i18n/utils'
import { PAGES } from '@/routes'
import { useLocation } from '@reach/router'
import { passwordIsSecure } from '@/utils/password-security'
import { useCookiesConfig } from '@/features/cookies/use-cookies'
import { CookieBanner } from '@/features/cookies/CookieBanner'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Alert, AlertType } from '@/components/Alert'

const PasswordResetPage = ({
  pageContext,
}: PageProps<never, { language: string }>): JSX.Element => {
  const { t, i18n } = useTranslation('nsPasswordResetPage')

  const Config = useConfig()

  if (i18n.language !== pageContext.language) {
    i18n.changeLanguage(pageContext.language)
  }

  const [alertSuccessShow, toggleAlertSuccessShow] = useToggle(false)
  const [alertErrorShow, toggleAlertErrorShow] = useToggle(false)

  const { token } = useSearchParams<{ token: string }>()
  const location = useLocation()
  const cookiesConfig = useCookiesConfig()

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    values,
    touched,
    setSubmitting,
    isSubmitting,
  } = useFormik<{
    password: string
    confirmPassword: string
    recaptcha: null | string
  }>({
    initialValues: {
      password: '',
      confirmPassword: '',
      recaptcha: null,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(t('nsValidations:required'))
        .test('security', t('nsValidations:password'), value => !!value && passwordIsSecure(value)),
      confirmPassword: Yup.string()
        .required(t('nsValidations:required'))
        .equals([Yup.ref('password')], t('nsValidations:passwordConfirmation')),

      recaptcha: Yup.string().required(t('nsValidations:required')).nullable(),
    }),
    onSubmit: ({ password, recaptcha }) => {
      setSubmitting(true)
      passwordReset(token || '', password, {
        [Config.RECAPTCHA_HEADER]: recaptcha as string,
      })
        .then(() => toggleAlertSuccessShow())
        .catch(() => toggleAlertErrorShow())
        .finally(() => setSubmitting(false))
    },
  })

  return (
    <>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name='robots' content='noindex, nofollow' />
        <meta name='facebook-domain-verification' content='gdt37htl4djqwmarnkdyct76lx1zos' />

        {/* Google Tag Manager */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T432GJH');
        `}</script>
        )}
        {/* End Google Tag Manager */}

        {/* Segment analytics */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=window.GATSBY_APP_SEGMENT_WRITEKEY;;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load(window.GATSBY_APP_SEGMENT_WRITEKEY);
          analytics.page();
          }}();
        `}</script>
        )}

        {/* Disallow cache */}
        <meta http-equiv='cache-control' content='max-age=0' />
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='expires' content='Tue, 01 Jan 1980 1:00:00 GMT' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>
      <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img className='h-6 m-auto' src={imgLogo} alt='Trazable' />
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>{t('title')}</h2>
        </div>

        <Card className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <Card.Body>
            <form className='space-y-6' action='#' method='POST' onSubmit={handleSubmit}>
              <p className='mt-1 mb-8 text-center text-sm text-gray-600'>{t('subtitle')}</p>
              <InputField
                id='password'
                name='password'
                type='password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label={t('password')}
                error={!!errors.password && touched.password}
                errorText={errors.password}
              />
              <InputField
                id='confirmPassword'
                name='confirmPassword'
                type='password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                label={t('confirmPassword')}
                error={!!errors.confirmPassword && touched.confirmPassword}
                errorText={errors.confirmPassword}
              />
              <div>
                <ReCAPTCHA
                  hl={i18n.language}
                  sitekey={Config.RECAPTCHA_SITEKEY}
                  onChange={token => setFieldValue('recaptcha', token)}
                />
                <p className='text-sm text-red-600'>{errors.recaptcha}</p>
              </div>

              <div>
                <Button
                  type='submit'
                  className='w-full'
                  disabled={isSubmitting}
                  style={{
                    background: 'linear-gradient(71.26deg, #DF992F 27.74%, #CF441C 96.11%)',
                  }}
                >
                  {t('submit')}
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>

      <Alert
        title={t('alert.success.title')}
        description={t('alert.success.description')}
        confirmText={t('alert.success.action')}
        type={AlertType.SUCCESS}
        show={alertSuccessShow}
        onConfirm={() => {
          navigate(`${getTranslatedPath(location.pathname, PAGES.HOME)}?login`)
        }}
        confirmStyle={{
          background: 'linear-gradient(71.26deg, #1FCFBF 27.74%, #0CB3A3 96.11%)',
          boxShadow: 'none',
        }}
      />

      <Alert
        title={t('alert.error.title')}
        description={t('alert.error.description')}
        confirmText={t('alert.error.action')}
        type={AlertType.ERROR}
        show={alertErrorShow}
        onConfirm={() => {
          navigate(getTranslatedPath(location.pathname, PAGES.PASSWORD_RECOVERY))
        }}
        confirmStyle={{
          background: 'linear-gradient(71.26deg, #1FCFBF 27.74%, #0CB3A3 96.11%)',
          boxShadow: 'none',
        }}
      />

      <CookieBanner />

      <div className='fixed top-8 left-8'>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => {
            navigate(PAGES.HOME)
          }}
        >
          <ChevronLeftIcon className='h-8' />
          <span className='text-2xl'>{t('nsActions:back')}</span>
        </div>
      </div>
    </>
  )
}

export default PasswordResetPage
